import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { HeaderBar, FooterBar, MainContent } from './components';
import { Welcome, AboutUs, Venue, GuestInfo, Rsvp } from './pages';
import './App.css';

function App() {
  return (
    <Router>
      
      <HeaderBar />
      <MainContent>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/venue" element={<Venue />} />
          <Route path="/guestInfo" element={<GuestInfo />} />
          <Route path="/rsvp" element={<Rsvp />} />
        </Routes>
      </MainContent>
      <FooterBar />
    </Router >
  );
}

export default App;