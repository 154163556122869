import { createGlobalStyle } from "styled-components";

import EyesomeItalic from "../../fonts/Eyesome-Italic.otf";
import EyesomeRegular from "../../fonts/Eyesome-Regular.otf";
import EyesomeScript from "../../fonts/Eyesome-Script.otf";
import Naskh from "../../fonts/Naskh.ttf";

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: eyesome-italic;
    src: url(${EyesomeItalic}) format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: eyesome-regular;
    src: url(${EyesomeRegular}) format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: eyesome-script;
    src: url(${EyesomeScript}) format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Naskh;
    src: url(${Naskh}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }
`;

export default FontStyles;