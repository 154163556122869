import firstMet from './images/1stmet.png';
import firstHome from './images/1sthome.png';
import firstHoliday from './images/1stholiday.png';
import engagement from './images/engagement.png';
import firstDate from './images/1stdate.png';

import { Container, Content, ImageDescription, Date, Image, Images, Text, Heading} from '../../components/Common/common.styles';

function AboutUsContainer() {
    return (
        <Content>
            <Heading>About Us</Heading>
            <Text>Our journey to becoming Mr & Mrs Kindleysides...</Text>
            <Container>
                <Images>
                    <Image src={firstMet} alt="first met" />
                    <Date>31st October 2010</Date>
                    <ImageDescription>We first met at Satans Hollow Halloween Party in Manchester. <br/>
                        Andy beat Liv's friend in the fancy dress competition resulting in the creation of a social media friendship,<br/> little did we know where we would end up..!
                    </ImageDescription>

                    <Image src={firstDate} alt="first date" />
                    <Date>4th May 2019</Date>
                    <ImageDescription>Our first date, we went bouldering at Substation, followed by dinner at TAPA in Bollington.<br/>
                        Dinner at TAPA became a weekly occurrence
                    </ImageDescription>

                    <Image src={firstHoliday} alt="first holiday" />
                    <Date>1st December 2019</Date>
                    <ImageDescription>Our first holiday together, we travelled to Budapest, Hungary.<br/> 
                        For the Christmas markets and mulled wine 🍷
                    </ImageDescription>

                    <Image src={firstHome} alt="first home" />
                    <Date>20th January 2021</Date>
                    <ImageDescription>We moved into our first home and so began our journey into all things dust and DIY.</ImageDescription>

                    <Image src={engagement} alt="engagement" />
                    <Date>7th June 2023</Date>
                    <ImageDescription>Our 'Yes' day, a very stealthy proposal on the shores of Riomaggiore marina in Italy at midnight.</ImageDescription>
                </Images>
            </Container>
        </Content>
    );
}

export default AboutUsContainer;