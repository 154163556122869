import styled from 'styled-components'
import commonVariables from '../Common/common.styles.vars'

export const Header = styled.header`
  background-color: #8A9A5B;
  color: white;
  text-align: center;
  position: fixed;
  width: 100%;
  height: ${commonVariables.headerHeight}px;
  display: flex;
  align-items: center;
  z-index: 10
`

export const Nav = styled.nav`
  width: 100%;
`

export const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const LinkItem = styled.li`
  display: inline;
  margin: 0 10px;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: relative;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    height: 100%;
    border-right: 1px solid #fff;
  };

  a {
    font-family: 'eyesome-script';
    font-size: 22px;
    color: white;
    text-decoration: none;
  };
`