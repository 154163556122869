const headerHeight = 80;
const footerHeight = 40;
const combinedHeaderFooterHeight = headerHeight + footerHeight;

const commonVariables =  {
    headerHeight,
    footerHeight,
    combinedHeaderFooterHeight
}

export default commonVariables