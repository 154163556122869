import styled from 'styled-components'

export const Form = styled.form``

export const Label = styled.label`
  margin: 5px;
`

export const Select = styled.select`
  margin: 5px;
  width: 50px;
`

export const Option = styled.option``


export const RsvpForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

export const RemoveButton = styled.div`
  font-family: 'cataneo-regular';
  font-size: large;
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  text-align: right;
`

export const RsvpResponse = styled.div`
  font-family: 'eyesome-script';
  font-weight: 500;
  font-size: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Guests = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Guest = styled.div`
  font-family: 'cataneo-regular';
  font-size: 1rem;
  font-weight: 300;
  padding: 3px;
  outline: 1px;
  outline-style: double;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 200px;
  margin: 20px;
`

export const ErrorMessage = styled.p`
  text-align: center;
  font-family: 'cataneo-regular';
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: bold;
  margin: 0px;
  color: red;
`

export const AddButton = styled.button`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const SubmitButton = styled.button`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`