import { Content, Image, Text, Heading, ExternalLink} from '../../components';
import hilltop from './images/hilltop.png';

function VenueContainer() {
    return (
        <Content>
            <Heading>Hilltop Country House</Heading>
            <ExternalLink href="https://yourhilltopwedding.com/the-venue" target="_blank" rel="noreferrer"><Image src={hilltop} alt=""/></ExternalLink>
            <Text fontSize="20px">Located just outside the village of Bollington where Olivia grew up, you will find our wedding venue.</Text>
            <Text fontSize="18px">Flash Lane, Prestbury, SK10 4ED</Text>
        </Content>
    );
}

export default VenueContainer;