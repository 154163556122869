import React from 'react';
import { Footer } from './FooterBar.styles';

function FooterBar() {
  return (
    <Footer>
      &copy; 2024 Andy Kindleysides
    </Footer>
  );
}

export default FooterBar;