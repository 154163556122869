import styled from 'styled-components'
import commonVariables from './common.styles.vars'
import icon from '../../images/icon.png'

export const Image = styled.img`
    width: 100%;
`

export const Background = styled.div`
    margin:0;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        justify-content: center;
        top: 0;
        
        width: 100%;
        height: 600px;
        
        background-repeat: no-repeat;                   
        background-image: url(${icon});
        background-size: contain; 
        background-position: center; 
        opacity: 0.09;
        z-index: -1; 
    }   
`

export const Container = styled.div``

export const Images = styled.div`
    height: 80%;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-content: center;
`

export const ImageDescription = styled.p`
    font-family: 'Naskh';
    font-size: 18px;
    font-weight: normal;
    margin-top: 1px;
    margin-bottom: 60px;    
`

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    overflow-y: auto;  
    flex: 1;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    
    padding-bottom: ${commonVariables.footerHeight}px;
    width: 100%;
    margin: 0;
`

export const Text = styled.p`
    text-align: center;
    font-family: 'Naskh';
    font-size: ${(props) => props.fontSize || '18px'};
    font-weight: normal;
`

export const MainContent = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${commonVariables.headerHeight}px;
    margin-bottom: ${commonVariables.footerHeight}px;
    padding-left: 20%;
    padding-right: 20%;
    height: 100%;    
    color: black;
    position: relative;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

export const Heading = styled.h1`
    justify-content: center;
    text-align: center;
    font-family: 'eyesome-script';
    font-size: ${(props) => props.fontSize || '2rem'};
    font-weight: bold;
`
export const SubHeading = styled.h2`
    font-family: 'eyesome-script';
    font-size: ${(props) => props.fontSize || '20px'};
    font-weight: bold;
`

export const Date = styled.p`
    font-family: 'eyesome-script';
    font-size: ${(props) => props.fontSize || '24px'};
    font-weight: 900;
    margin: 1px;
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
`

export const ExternalLink = styled.a`
    font-size: ${(props) => props.fontSize || '22px'};
    font-family: 'Naskh';
    text-decoration: ${(props) => props.$nounderline === true ? 'none' : 'underline' };
`