import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Nav, LinkList, LinkItem } from './HeaderBar.styles';

function HeaderBar() {
  return (
    <Header>
      <Nav>
        <LinkList>
          <LinkItem><Link to="/about">About Us</Link></LinkItem>
          <LinkItem><Link to="/venue">Venue</Link></LinkItem>
          <LinkItem><Link to="/guestInfo">Guest Info</Link></LinkItem>
          <LinkItem><Link to="/rsvp">RSVP</Link></LinkItem>
        </LinkList>
      </Nav>
    </Header>
  );
}

export default HeaderBar;
