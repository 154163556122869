import {
    FAQContainer,
    FAQ,
    Text,
    Heading,
    ExternalLink,
    SubHeading 
} from "../../components";

function GuestInfoContainer() {
    const faqData = [
        {
            question: 'What time should I arrive?',
            answer: (<>
                 <Text>Our ceremony is due to take place at 12pm.</Text>
                 <Text>Please ensure you arrive with enough time to take your seat.</Text>
            </>)
        },
        {
            question: 'What time will the day end?',
            answer: (<>
                 <Text>Our last dance will be just before midnight.</Text>
            </>)
        },
        {
            question: 'How do I get to the venue?',
            answer: (<>
                        <SubHeading>By Car</SubHeading>
                        <Text>If you're driving, there is plenty of parking available at the venue</Text>
                        <Text>What3Words: <ExternalLink href="https://w3w.co/racetrack.vine.gazes" target="_blank" rel="noreferrer">racetrack.vine.graces</ExternalLink><br/>
                        GoogleMaps: <ExternalLink href="https://maps.app.goo.gl/bbhSZEwm4aT2Ex8K9?g_st=iw" target="_blank" rel="noreferrer" $nounderline={true}>📍</ExternalLink></Text>
                        
                        <SubHeading>By Taxi</SubHeading>
                        <ExternalLink href="https://www.silvertowntaxis.com" target="_blank" rel="noreferrer">Silvertown Taxis</ExternalLink>
                        <Text>01625 616666</Text>
                        <ExternalLink href="https://www.lynxtaxis.co.uk" target="_blank" rel="noreferrer">Lynx Taxis</ExternalLink>
                        <Text>01625 434343</Text>
                        <SubHeading>By Train</SubHeading>
                        <Text>Prestbury Station - 1 mile <br/>
                            Macclesfield Station - 3 miles</Text>
                        <SubHeading>By Air</SubHeading>
                        <Text>Manchester International Airport - 10 miles</Text>
                    </>)
        },
        {
            question: 'Can I bring a plus one?',
            answer: (<>
                 <Text>Our celebration is strictly RSVP only.</Text>
                 <Text>We will only be able to accomodate those addressed on your invitation.</Text>
            </>)
        },
        {
            question: 'When is the RSVP deadline?',
            answer: (<>
                 <Text>Let us know if you can, or cannot make it no later than Friday 28th February 2025</Text>
                 <Text>Please use the RSVP tab above.</Text>
            </>)
        },
        {
            question: 'Can I bring my children?',
            answer: (<>
                 <Text>Although we love all of our family and friends' children, we are unable to extend your invitation to them.</Text>
                 <Text>Hopefully you'll see this as an opportunity to properly let your hair down and enjoy the day</Text>
            </>)
        },
        {
            question: 'Where can I stay?',
            answer: (<>
                <ExternalLink href="https://www.thetytheringtonclub.com/" target="_blank" rel="noreferrer">The Tytherington Club</ExternalLink>
                <Text>Dorchester Way, Tytherington, SK10 2JP - discount available when mentioning Hilltop Country House.</Text>
                <ExternalLink href="https://www.premierinn.com/gb/en/hotels/england/cheshire/macclesfield/macclesfield-north.html?cid=GLBC_MACMID" target="_blank" rel="noreferrer">Premier Inn (Macclesfield North)</ExternalLink>
                <Text>Tytherington Business Park, Springwood Way, SK10 2XA</Text>
                <ExternalLink href="https://flatcaphotels.com/the-bridge-prestbury/" target="_blank" rel="noreferrer">The Bridge</ExternalLink>
                <Text>Prestbury Village, SK10 4DQ</Text>
            </>)
        },
        {
            question: 'What should I wear?',
            answer: (<>                
                <Text>We'd love to see you dressed to impress!</Text>
                <Text>Please note our ceremony will be taking place outside (weather permitting), but don't worry, the reception will be taking place indoors</Text>
            </>)
        },
        {
            question: 'What about gifts?',
            answer: (<>                
                <Text>Please do not feel obliged to buy us a gift, all we are expecting is you to celebrate the day with us.</Text>
                <Text>There will be a box for cards on the day.</Text>
                <Text>If you would like to gift us something, a contribution towards our honeymoon would be greatly appreciated, Thank You x</Text>
            </>)
        },{
            question: 'What if I have more questions?',
            answer: (<>                
                <Text>If you have any other questions, please email us at <ExternalLink href="mailto:thekindleysideswedding@gmail.com" target="_blank" rel="noreferrer">thekindleysideswedding@gmail.com</ExternalLink></Text>
            </>)
        }
    ];

    return (
        <FAQContainer>
            <Heading>Frequently Asked Questions</Heading>
            {faqData.map((faq, index) => (
                <FAQ key={index} question={faq.question} answer={faq.answer} />
            ))}
        </FAQContainer>
    );
}

export default GuestInfoContainer;