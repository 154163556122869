import styled from 'styled-components'

export const FAQContainer = styled.div`
    max-width: 800px;
    font-family: 'Naskh';
    font-size: 30px;
    font-weight: normal;    
`

export const FAQItem = styled.div`
    margin-bottom: 10px;
    font-family: 'Naskh';
    font-size: 16px;
    font-weight: normal;
`

export const FAQTitle = styled.p`
    width: 100%;
    padding-bottom: 5px;
    border: none;
    text-align: center;
    outline: none;
    font-family: 'eyesome-script';
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    :hover {
        background-color: #0056b3;
    }
`

export const FAQContent = styled.div`
    border: 1px solid #a0a1a3;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-height: ${props => (props.$isopen ? '100%' : '0')};
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-left: 10%;
    padding-right: 10%;    
`