import { Background, Text, Heading } from '../../components/Common/common.styles';

function WelcomeContainer() {
    return (
        <Background>
            <Heading>4th May 2025</Heading>
            <Text>You are invited to the wedding of Olivia Jackson & Andrew Kindleysides</Text>
            <Text>We cannot wait to celebrate with you!</Text>
        </Background>
    );
}

export default WelcomeContainer;