import styled from 'styled-components'
import commonVariables from '../Common/common.styles.vars'

export const Footer = styled.footer`
  font-family: 'eyesome-regular';
  font-size: larger;
  background-color: #333;
  color: white;
  align-content: center;
  text-align: center;
  width: 100%;
  margin: 0;
  position: fixed;
  bottom: 0;
  height: ${commonVariables.footerHeight}px;
  z-index: 10
`