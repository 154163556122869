import { useCallback, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { RsvpForm, Form, RemoveButton, RsvpResponse, Guests, Guest, AddButton, SubmitButton, ButtonContainer, Label, Select, Option, ErrorMessage } from "./Rsvp.styles"
import { Content, Heading, Text, LoadingContainer } from "../../components";
import config from "../../config/config";
import Cookies from 'js-cookie';
import { Hearts } from 'react-loader-spinner';

export const RsvpContainer = () => {
    const [loading, setLoading] = useState(false);
    const [showRSVP, setShowRSVP] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const cookie = Cookies.get('rsvpMade');
        
        if (cookie) {
            setShowRSVP(false);
        }
    }, []);

    const { register, control, handleSubmit, getValues, formState: { errors } } = useForm({
        defaultValues: {
            guests: [{ firstName: "", lastName: "", dietaryRequirements: "", attending: "yes" },{ firstName: "", lastName: "", dietaryRequirements: "", attending: "yes" }]
        }
    });

    const onSubmit = useCallback(() => {
        let rsvpRequest = getValues().guests.map(item => ({
            ...item,
            attending: item.attending.toLowerCase() === 'yes'
        }));
        sendRsvp(rsvpRequest);
    }, [getValues]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "guests"
    });

    const sendRsvp = async (rsvpData) => {
        setLoading(true);
        try {
            const response = await fetch(config.rsvpApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(rsvpData),
            });
            if (!response.ok) {
                setLoading(false);
                throw new Error('Network response was not ok');
            }

            Cookies.set('rsvpMade', 'true', { expires: 1 }); 
            setSubmitted(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Content>
            <Heading>RSVP</Heading> {loading ? (
                <LoadingContainer>
                    <Hearts type="Hearts" color="#8A9A5B" height={80} width={80} />
                </LoadingContainer>
                
            ): submitted || !showRSVP ?
                    (
                        <RsvpResponse>
                            Thank you for your response!
                        </RsvpResponse>
                    ) :
                    (
                        <>
                            <Text>Let us know if you can or cannot make it by no later than Friday 28th February 2025</Text>
                            <Text>Please ensure you add details for all guests in your party before submitting.</Text>
                            <ButtonContainer>
                                <AddButton type="button" onClick={() => { append({ firstName: "", lastName: "", dietaryRequirements: "", attending: "yes" }, { shouldFocus: false }) }}>
                                    + Guest
                                </AddButton>
                            </ButtonContainer>
                            <RsvpForm>
                                <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Guests>
                                        {fields.map((item, index) => {
                                            return (
                                                <Guest key={item.id}>
                                                    {index === 0 && <div style={{ margin: '10px' }} />}
                                                    {index > 0 && <RemoveButton onClick={() => { remove(index) }}>x</RemoveButton>}
                                                    <Label>First Name <input {...register(`guests.${index}.firstName`, { required: "First Name is required", minLength: { value: 3, message: 'Name must be at least 3 characters long' }, maxLength: { value: 15, message: 'Name no more than 15 characters' } })} /></Label>
                                                    {
                                                        errors.guests && errors.guests[index] && errors.guests[index].firstName && (
                                                            <ErrorMessage>{errors.guests[index].firstName.message}</ErrorMessage>)
                                                    }

                                                    <Label>Last Name <input {...register(`guests.${index}.lastName`, { required: "Last Name is required", minLength: { value: 3, message: 'Last Name must be at least 3 characters long' }, maxLength: { value: 20, message: 'Last Name no more than 20 characters' } })} /></Label>
                                                    {
                                                        errors.guests && errors.guests[index] && errors.guests[index].lastName && (
                                                            <ErrorMessage>{errors.guests[index].lastName.message}</ErrorMessage>)
                                                    }

                                                    <Label>Dietary Requirements <input {...register(`guests.${index}.dietaryRequirements`, { required: "Dietary Requirements are required", minLength: { value: 3, message: 'Dietary Requirements must be at least 3 characters long' }, maxLength: { value: 20, message: 'Dietary Requirements no more than 20 characters' } })} /></Label>
                                                    {
                                                        errors.guests && errors.guests[index] && errors.guests[index].dietaryRequirements && (
                                                            <ErrorMessage>{errors.guests[index].dietaryRequirements.message}</ErrorMessage>)
                                                    }

                                                    <Label>Attending
                                                        <Select {...register(`guests.${index}.attending`)}>
                                                            <Option value="yes">Yes</Option>
                                                            <Option value="no">No</Option>
                                                        </Select>
                                                    </Label>
                                                </Guest>
                                            );
                                        })}
                                    </Guests>
                                    <ButtonContainer><SubmitButton type="submit">Submit</SubmitButton></ButtonContainer>
                                </Form>
                            </RsvpForm>
                        </>
                    )}
        </Content>);
}

export default RsvpContainer;