import React, { useState } from 'react';
import {   
    FAQItem,
    FAQContent,
    FAQTitle
} from "./FAQ.styles";

const FAQ = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFAQ = () => {
        setIsOpen(!isOpen);
    };

    return (
        <FAQItem>
            <FAQTitle onClick={toggleFAQ}>{question}</FAQTitle>
            <FAQContent $isopen={isOpen}>
                {answer}
            </FAQContent>
        </FAQItem>        
    );
};

export default FAQ;